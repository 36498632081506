// 默认 Graphik-Regular，标题栏 Graphik-Medium，logo onto用 NoumenaFont-Bold， 标题用 Silka-Bold，统计数字用 Silka-Medium

// Graphik-Regular
@font-face {
  font-family: Graphik-Regular;
  src: url("../assets/fonts/Graphik-Regular/Graphik-Regular.svg"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Graphik-Regular/Graphik-Regular.woff2") format("woff2"), /* Modern Browsers */
  url("../assets/fonts/Graphik-Regular/Graphik-Regular.woff") format("woff"), /* Modern Browsers */
  url("../assets/fonts/Graphik-Regular/Graphik-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../assets/fonts/Graphik-Regular/Graphik-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../assets/fonts/Graphik-Regular/Graphik-Regular.otf") format("opentype"), /* Open Type Font */
  url("../assets/fonts/Graphik-Regular/Graphik-Regular.svg") format("svg"); /* Legacy iO¬S */
  font-weight: normal;
  font-display: auto;
}

// Graphik-Medium
@font-face {
  font-family: Graphik-Medium;
  src: url("../assets/fonts/Graphik-Medium/Graphik-Medium.svg"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Graphik-Medium/Graphik-Medium.woff2") format("woff2"), /* Modern Browsers */
  url("../assets/fonts/Graphik-Medium/Graphik-Medium.woff") format("woff"), /* Modern Browsers */
  url("../assets/fonts/Graphik-Medium/Graphik-Medium.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../assets/fonts/Graphik-Medium/Graphik-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../assets/fonts/Graphik-Medium/Graphik-Medium.otf") format("opentype"), /* Open Type Font */
  url("../assets/fonts/Graphik-Medium/Graphik-Medium.svg") format("svg"); /* Legacy iO¬S */
  font-weight: bold;
  font-display: auto;
}

// NoumenaFont-Bold
@font-face {
  font-family: NoumenaFont-Bold;
  src: url("../assets/fonts/NoumenaFont/NoumenaFont.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/NoumenaFont/NoumenaFont.woff2") format("woff2"), /* Modern Browsers */
  url("../assets/fonts/NoumenaFont/NoumenaFont.woff") format("woff"), /* Modern Browsers */
  url("../assets/fonts/NoumenaFont/NoumenaFont.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../assets/fonts/NoumenaFont/NoumenaFont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../assets/fonts/NoumenaFont/NoumenaFont.otf") format("opentype"), /* Open Type Font */
  url("../assets/fonts/NoumenaFont/NoumenaFont.svg") format("svg"); /* Legacy iOS */
  font-weight: bold;
  font-display: auto;
}

// Silka-Medium
@font-face {
  font-family: Silka-Medium;
  src: url("../assets/fonts/Silka-Medium/silka-medium-webfont.svg"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Silka-Medium/silka-medium-webfont.woff2") format("woff2"), /* Modern Browsers */
  url("../assets/fonts/Silka-Medium/silka-medium-webfont.woff") format("woff"), /* Modern Browsers */
  url("../assets/fonts/Silka-Medium/silka-medium-webfont.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../assets/fonts/Silka-Medium/silka-medium-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../assets/fonts/Silka-Medium/silka-medium-webfont.otf") format("opentype"), /* Open Type Font */
  url("../assets/fonts/Silka-Medium/silka-medium-webfont.svg") format("svg"); /* Legacy iO¬S */
  font-weight: 500;
  font-display: auto;
}

// Silka-Bold
@font-face {
  font-family: Silka-Bold;
  src: url("../assets/fonts/Silka-Bold/silka-bold-webfont.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/Silka-Bold/silka-bold-webfont.woff2") format("woff2"), /* Modern Browsers */
  url("../assets/fonts/Silka-Bold/silka-bold-webfont.woff") format("woff"), /* Modern Browsers */
  url("../assets/fonts/Silka-Bold/silka-bold-webfont.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../assets/fonts/Silka-Bold/silka-bold-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../assets/fonts/Silka-Bold/silka-bold-webfont.otf") format("opentype"), /* Open Type Font */
  url("../assets/fonts/Silka-Bold/silka-bold-webfont.svg") format("svg"); /* Legacy iO¬S */
  font-weight: bold;
  font-display: auto;
}

// DINPro
@font-face {
  font-family: "DINPro";
  src: url("../assets/fonts/DINPro/DINPro.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/DINPro/DINPro.woff2") format("woff2"), /* Modern Browsers */
  url("../assets/fonts/DINPro/DINPro.woff") format("woff"), /* Modern Browsers */
  url("../assets/fonts/DINPro/DINPro.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../assets/fonts/DINPro/DINPro.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../assets/fonts/DINPro/DINPro.otf") format("opentype"), /* Open Type Font */
  url("../assets/fonts/DINPro/DINPro.svg") format("svg"); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}


// SamsungSharpSans
@font-face {
  font-family: SamsungSharpSans;
  src:
  url("../assets/fonts/SamsungSharpSans/samsungsharpsans.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

// SamsungSharpSans-Medium
@font-face {
  font-family: SamsungSharpSans-Medium;
  src:
  url("../assets/fonts/SamsungSharpSans/samsungsharpsans-medium.otf") format("opentype");
  font-weight: 500;
  font-display: auto;
}

// SamsungSharpSans-Bold
@font-face {
  font-family: SamsungSharpSans-Bold;
  src:
  url("../assets/fonts/SamsungSharpSans/samsungsharpsans-bold.otf") format("opentype");
  font-weight: bold;
  font-display: auto;
}