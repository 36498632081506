.g-clear-btn {
  outline: none;
  border: none;
  background: none;
}

.g-container {
  padding: 40px;
  max-width: 1200px;
}

.g-container-1280 {
  padding: 0 80px 40px 80px;
  max-width: 1240px;
}
.g-card {
  background: #fff;
  padding: 30px 40px;
  box-shadow: 0 10px 40px 0 rgba(6, 8, 69, 0.02);
  border-radius: 8px;
}

.g-sub-title {
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 0;
  font-size: 18px;
  line-height: 21px;
  color: var(--color-base);
  font-weight: bold;

  &.first {
    margin-top: 0;
  }
}

.g-font-bold {
  font-weight: bold;
  font-family: 'Silka-Bold', 'PingFang SC', 微软雅黑, 黑体, Microsoft YaHei,
    SimHei, 'Open Sans', sans-serif;
}

.g-font-sam {
  font-weight: bold;
  font-family: 'NoumenaFont-Bold', 'PingFang SC', 微软雅黑, 黑体,
    Microsoft YaHei, SimHei, 'Open Sans', sans-serif;
}

.g-font-num {
  font-weight: 500;
  font-family: 'DINPro', 'PingFang SC', 微软雅黑, 黑体, Microsoft YaHei, SimHei,
    'Open Sans', sans-serif;
}

.g-table-wrapper {
  overflow: auto;

  .ant-table-thead > tr > th {
    color: var(--color-des);
    font-weight: 400;
    background: rgba(6, 8, 69, 0.02);
    border-bottom: none;
    line-height: 20px;
    padding: 10px;
  }

  .ant-table-tbody > tr > td {
    color: var(--color-base);
    padding: 13px 10px;
    line-height: 20px;
    border-bottom: 1px solid #f2f2f2;
  }
}

.mid-font {
  font-size: 16px;
  font-weight: 500;
}

.threeellips {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.center_txt {
  padding: 40px 20px 0 60px;
  h3 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    position: relative;
    word-break: break-word;
    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
  .index_sort {
    &::before {
      position: absolute;
      left: -30px;
      top: 0;
    }
  }
  .sort1 {
    &::before {
      content: '1 -';
    }
  }
  .sort2 {
    &::before {
      content: '2 -';
    }
  }
  .sort3 {
    &::before {
      content: '3 -';
    }
  }
  .sort4 {
    &::before {
      content: '4 -';
    }
  }
  .sort5 {
    &::before {
      content: '5 -';
    }
  }
  .sort6 {
    &::before {
      content: '6 -';
    }
  }
  .sort7 {
    &::before {
      content: '7 -';
    }
  }
  p {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
  }
}
