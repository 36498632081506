// do not reset h1,h2..
html, body {
  padding: 0;
  margin: 0;
  color: var(--color-base);
  font-family: "PingFang SC", 微软雅黑, 黑体, Microsoft YaHei, SimHei, 'Open Sans', sans-serif;

  th {
    white-space: nowrap;
  }
}
ol,ul,dl,li {
  padding: 0;
  margin: 0;
  list-style: none;
}
a, a:hover, a:visited, a:link, a:active {
  text-decoration: none;
  outline: none;
}

.ant-modal-footer, .ant-modal-header {
  padding: 20px 24px;
}

.ant-modal-header {
  font-size: 20px;
}

.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
  display: none;
}

.ant-tabs-tab {
  margin-right: 40px;
}

// switch background
.ant-switch-checked {
  background: var(--color-theme);
}
