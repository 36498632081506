.ant-modal-body {
  padding: 0;
}
.ant-modal-content {
  border-radius: 10px;
}
.ant-modal-header {
  border-radius: 10px 10px 0 0;
}
#subForm {
  text-align: left;
  .ant-form-item {
    .ant-form-item-label {
      padding-bottom: 18px;
      label {
        color: var(--color-base);
        font-size: 14px;
        line-height: 20px;
      }
      label.ant-form-item-required {
        &::before {
          display: none;
        }
      }
    }
    .ant-form-item-control-input {
      .ant-input {
        border: none;
        outline: none;
        background: rgba(249, 249, 250, 1);
        border-radius: 4px;
        border: 1px solid rgba(232, 232, 238, 1);
        padding: 20px;
        font-size: 14px;
        line-height: 17px;
        box-shadow: none;
        color: var(--color-base);
        &:focus {
          border-color: var(--color-base);
        }
        &::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(181, 181, 199, 1);
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(181, 181, 199, 1);
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(181, 181, 199, 1);
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(181, 181, 199, 1);
        }
      }
      textarea {
        resize: none;
      }
      .ant-select {
        .ant-select-selector {
          height: 54px;
          border-radius: 4px;
          border: 1px solid rgba(232, 232, 238, 1) !important;
          background: rgba(249, 249, 250, 1);
          padding: 0;
          .ant-select-selection-search {
            // left: 0;
            .ant-select-selection-search-input {
              padding: 20px;
              font-size: 14px;
              line-height: 17px;
              height: 54px;
            }
          }
        }
        .ant-select-arrow {
          color: #000;
        }
      }
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
        padding-left: 20px;
        line-height: 52px !important;
      }
      .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
        color: rgba(181, 181, 199, 1);
        opacity: 1;
      }
      .ant-select.ant-select-focused {
        .ant-select-selector {
          box-shadow: none;
          border-color: var(--color-base);
        }
      }
    }
    .ant-form-item-control {
      .ant-form-item-explain > div {
        color: #f56c6c;
      }
    }
  }
}
.cusPrimaryLabelCss {
  color: var(--color-base);
  font-size: 14px;
  padding-bottom: 18px;
}
.primarySelectCss {
  padding: 0 !important;
  border-radius: 4px;
  .ant-select-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: 42px;
    font-size: 14px;
    line-height: 14px;
  }
}
